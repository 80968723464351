import React from "react";
import { Link } from "react-router-dom";
import Line from "../../layout/Line";
import classes from "./ReactProjects.module.css";

const ReactProjects = ({ bulgarian }) => {
  return (
    <React.Fragment>
      <section className={classes.projects__bg} id="react">
        <div className={classes.projects__header}>
          {bulgarian ? (
            <h2 className="bg__heading">Моите REACT приложения</h2>
          ) : (
            <h2>MY REACT APPLICATIONS</h2>
          )}
          <Line />
        </div>
        <div className="container">
          <section className={classes.projects__section}>
            <article>
              <div className={classes.application__header}>
                <h4>Fullstack MERN application</h4>
                {bulgarian ? (
                  <h2 className="bg__heading">Майстор готвач</h2>
                ) : (
                  <h2>THE COOK MASTER</h2>
                )}

                <Line />
              </div>
              <h4 className={classes.subheadings}>
                {bulgarian ? "Използвани технологии" : "Technologies used"}
              </h4>
              <div className={classes.projects__tech_stack}>
                <h5>React</h5>
                <h5>CSS3</h5>
                <h5>React Router</h5>
                <h5>React Toastify</h5>
                <h5>Redux</h5>
                <h5>NodeJS</h5>
                <h5>MongoDB</h5>
                <h5>Express</h5>
                <h5>Multer</h5>
                <h5>Mongoose</h5>
              </div>

              <article className={classes.projects__image_res}>
                <img
                  src={require("../../../assets/cookmaster1.png")}
                  alt="cooking"
                />
                <img
                  src={require("../../../assets/cookmaster2.png")}
                  alt="cooking2"
                />
              </article>
              <h4 className={classes.subheadings}>
                {bulgarian ? "Резюме" : "Overview"}
              </h4>

              {bulgarian ? (
                <p className="bg__para">
                  Фулстак приложение, изградено с React на frontend-а и NodeJs и
                  Express на backend-a. Представлява блог за рецепти, където
                  посетителите могат да разглеждат отделните рецепти, както и да
                  се регистрират, да добавят, редактират и изтриват свои.
                  Регистрирани посетители на сайта могат също и да оставят
                  коментари под рецептите.
                </p>
              ) : (
                <p>
                  A fullstack application built with React on the front end and
                  NodeJS and Express on the backend. It is a recipe blog where
                  users can register, view recipies, add, edit and delete their
                  own ones. Registered visitors could also add comments to the
                  recipes.
                </p>
              )}
              <div className={classes.projects__buttons}>
                <a
                  href="https://cookmaster.uzzunov.com/"
                  target="_blank"
                  rel="noreferrer"
                  className={`btn__primary ${
                    bulgarian ? classes.bg_button : ""
                  }`}
                >
                  {bulgarian ? "Посети сайта" : "Check it"}
                </a>
                <a
                  href="https://github.com/LachezarUzunov/react-cooking-app"
                  target="_blank"
                  rel="noreferrer"
                  className={`btn__secondary ${
                    bulgarian ? classes.bg_button : ""
                  }`}
                >
                  {bulgarian ? "Проектът в Github" : "View on Github"}
                </a>
              </div>
            </article>
            <article className={classes.projects__image}>
              <img
                src={require("../../../assets/cookmaster1.png")}
                alt="cooking"
              />
              <img
                src={require("../../../assets/cookmaster2.png")}
                alt="cooking2"
              />
            </article>
          </section>
        </div>
      </section>
      <section className={classes.projects__bg}>
        <div className="container">
          <section className={classes.projects__section}>
            <article className={classes.projects__image}>
              <img
                src={require("../../../assets/moviezilla1.png")}
                alt="movie2"
              />
              <img
                src={require("../../../assets/moviezilla2.png")}
                alt="movie3"
              />
            </article>
            <article>
              <div className={classes.application__header}>
                <h4>Fullstack MERN app with themoviedb API</h4>
                {bulgarian ? (
                  <h2 className="bg__heading">Moviezilla</h2>
                ) : (
                  <h2>MOVIEZILLA</h2>
                )}
                <Line />
              </div>

              <h4 className={classes.subheadings}>
                {bulgarian ? "Използвани технологии" : "Technologies used"}
              </h4>
              <div className={classes.projects__tech_stack}>
                <h5>React</h5>
                <h5>CSS3</h5>
                <h5>Font Awesome</h5>
                <h5>RESTful API</h5>
                <h5>React Router</h5>
                <h5>React Toastify</h5>
                <h5>Redux</h5>
                <h5>NodeJS</h5>
                <h5>MongoDB</h5>
                <h5>Express</h5>
                <h5>Bcryptjs</h5>
                <h5>Jsonwebtoken</h5>
                <h5>Mongoose</h5>
              </div>

              <article className={classes.projects__image_res}>
                <img
                  src={require("../../../assets/moviezilla1.png")}
                  alt="movie2"
                />
                <img
                  src={require("../../../assets/moviezilla2.png")}
                  alt="movie3"
                />
              </article>
              <h4 className={classes.subheadings}>
                {bulgarian ? "Резюме" : "Overview"}
              </h4>

              {bulgarian ? (
                <p className="bg__para">
                  Фулстак приложение, изградено с React, themoviedb.org API,
                  както и NodeJS и Express на бекенда. Посетителите могат да
                  търсят заглавия на филми или да качат текстов файл със списък
                  със заглавия, след което да сортират получените резултати от
                  търсачката и да премахнат ненужните резултати, както и да
                  направят повторно търсене, като редактират някое от
                  първоначално зададените заглавия. Потребителите могат да
                  създадат лист и да добавят филми към него.
                </p>
              ) : (
                <p>
                  Fullstack MERN application built with React, the moviedb.org
                  API and NodeJS and Express on the backend where visitors could
                  search for a movie or upload file with a list of movies, then
                  sort the results and remove films from the API response. Then
                  they could edit some of the initial film titles and do another
                  search and finally create and save the list of movies, and add
                  films later on to the watchlist.
                </p>
              )}
              <div className={classes.projects__buttons}>
                <a
                  href="https://moviezilla.uzzunov.com/"
                  target="_blank"
                  rel="noreferrer"
                  className={`btn__primary ${
                    bulgarian ? classes.bg_button : ""
                  }`}
                >
                  {bulgarian ? "Посети сайта" : "Check it"}
                </a>

                <a
                  href="https://github.com/LachezarUzunov/moviezilla"
                  target="_blank"
                  rel="noreferrer"
                  className={`btn__secondary ${
                    bulgarian ? classes.bg_button : ""
                  }`}
                >
                  {bulgarian ? "Проектът в Github" : "View on Github"}
                </a>
              </div>
            </article>
          </section>
        </div>
      </section>
      <section className={classes.projects__bg}>
        <div className="container">
          <section className={classes.projects__section}>
            <article>
              <div className={classes.application__header}>
                <h4>Fullstack MERN application</h4>
                {bulgarian ? (
                  <h2 className={`bg__heading`}>Мементо</h2>
                ) : (
                  <h2>MEMENTO SHOP</h2>
                )}
                <Line />
              </div>
              <h4 className={classes.subheadings}>
                {bulgarian ? "Използвани технологии" : "Technologies used"}
              </h4>
              <div className={classes.projects__tech_stack}>
                <h5>React</h5>
                <h5>CSS3</h5>
                <h5>React Router</h5>
                <h5>Bootstrap</h5>
                <h5>Redux</h5>
                <h5>Paypal API</h5>
                <h5>Axios</h5>
                <h5>NodeJS</h5>
                <h5>MongoDB</h5>
                <h5>Express</h5>
                <h5>Bcryptjs</h5>
                <h5>Mongoose</h5>
                <h5>Express Async Handler</h5>
                <h5>Jsonwebtoken</h5>
              </div>
              <article className={classes.projects__image_res}>
                <img src={require("../../../assets/shop2.png")} alt="shop1" />
                <img src={require("../../../assets/shop1.png")} alt="shop3" />
              </article>
              <h4 className={classes.subheadings}>
                {bulgarian ? "Резюме" : "Overview"}
              </h4>

              <p className={bulgarian ? "bg__para" : ""}>
                {bulgarian
                  ? "Малък онлайн магазин с няколко примерни продукта. Посетителите могат да се регистрират и да закупят продукт, да направят плащане през PayPal, както и да проследят поръчката си. Има също така и отделен админ панел, в който потребители с администраторски права могат да добавят нови продукти и да менажират доставките."
                  : "Small online store with sample products where visitors could register and purchase a product, submit a payment via PayPal and track their order. There is also a separate admin panel where admin user could manage products as well as deliveries."}
              </p>
              <div className={classes.projects__buttons}>
                <Link
                  to={"/projects/memento-shop"}
                  className={`btn__primary ${
                    bulgarian ? classes.bg_button : ""
                  }`}
                >
                  {bulgarian ? "Научи повече" : "Learn More"}
                </Link>
                <a
                  href="https://github.com/LachezarUzunov/memento-mern-e-commerce-app"
                  target="_blank"
                  rel="noreferrer"
                  className={`btn__secondary ${
                    bulgarian ? classes.bg_button : ""
                  }`}
                >
                  {bulgarian ? "Проектът в Github" : "View on Github"}
                </a>
              </div>
            </article>
            <article className={classes.projects__image}>
              <img src={require("../../../assets/shop2.png")} alt="shop1" />
              <img src={require("../../../assets/shop1.png")} alt="shop3" />
            </article>
          </section>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ReactProjects;
