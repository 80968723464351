import React from "react";
import Line from "../../layout/Line";
import classes from "./Header.module.css";
import CV_Lachezar_Uzunov from "../../../assets/CV_Lachezar_Uzunov.pdf";
import CV_Lachezar_Z_Uzunov from "../../../assets/CV_Lachezar_Z_Uzunov.pdf";

const Header = ({ bulgarian }) => {
  return (
    <section className={classes.header__section}>
      <div className={classes.header__bg}>
        <div className={`${classes.header} ${"container"}`}>
          {bulgarian ? (
            <div className={classes.header__inner}>
              <div>
                <div>
                  <h2 className="bg__heading">
                    Здравейте, аз съм Лъчезар Узунов
                  </h2>
                  <h2 className="bg__heading">Софтуерен Инженер</h2>
                  <Line />
                  <p className={classes.desc__bg}>
                    Мотивиран съм и жаден да науча нови технологии и да работя
                    по изграждането на вълнуващи проекти!
                  </p>
                  <br />
                  <p className={classes.desc__bg}>
                    Аз съм също баща, професионален рокаджия и аматьор китарист.
                  </p>
                </div>
                {/* <div>
               <img
                 className={classes.guitar__me}
                 src={require("../../../assets/guitar.jpg")}
                 alt="guitarist_picture_of_me"
               />
             </div> */}
              </div>

              <div className={classes.btns__div}>
                <a
                  download
                  className={`btn__primary ${classes.bg_button_header}`}
                  href={CV_Lachezar_Z_Uzunov}
                >
                  Свали автобиография
                </a>
                <a
                  className={`btn__secondary ${classes.bg_button_header}`}
                  href="#react"
                >
                  Виж проектите ми
                </a>
              </div>
            </div>
          ) : (
            <div className={classes.header__inner}>
              <div>
                <div>
                  <h2>Hello, I am Lachezar Uzunov</h2>
                  <h2>Frontend Software Engineer</h2>
                  <Line />
                  <p className={classes.desc}>
                    I am motivated and eager to learn new technologies and
                    passionate about building exciting projects!
                  </p>
                  <br />
                  <p className={classes.desc}>
                    I am also a father, a hard rock lover and an amateur
                    guitarist.
                  </p>
                </div>
                {/* <div>
              <img
                className={classes.guitar__me}
                src={require("../../../assets/guitar.jpg")}
                alt="guitarist_picture_of_me"
              />
            </div> */}
              </div>

              <div className={classes.btns__div}>
                <a download className="btn__primary" href={CV_Lachezar_Uzunov}>
                  Download Resume
                </a>
                <a className="btn__secondary" href="#react">
                  View My Projects
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Header;
