import React from "react";
import classes from "./WordPressProjects.module.css";
import Line from "../../layout/Line";

const WordPressProjects = ({ bulgarian }) => {
  return (
    <section className={classes.wordpress__section} id="wordpress">
      <section className="container">
        <div className={classes.wordpress__section_header}>
          <h4>
            {bulgarian
              ? "АКТИВНИ БИЗНЕС САЙТОВЕ, ИЗРАБОТЕНИ ЗА КЛИЕНТИ И ПРИЯТЕЛИ"
              : "LIVE BUSINESS SITES BUILT FOR CLIENTS"}
          </h4>
          {bulgarian ? (
            <h2 className="bg__heading">WordPress проекти</h2>
          ) : (
            <h2>WORDPRESS PROJECTS</h2>
          )}
          <Line />
        </div>
        <section className={classes.sites__section}>
          <article className={classes.article__container}>
            <img src={require("../../../assets/teasite.png")} alt="teadesign" />
            {bulgarian ? (
              <div className={classes.site__info}>
                <h4>ПОРТФОЛИО НА ГРАФИЧЕН ДИЗАЙНЕР</h4>

                <p className="bg__para">
                  Увлекателен и приятен проект, по който работих със и помогнах
                  на професионален продуктов фотограф и графичен дизайнер да
                  премине онлайн и да покаже своята работа.
                </p>

                <a
                  className={`btn__secondary ${classes.bg_button}`}
                  target="_blank"
                  rel="noreferrer"
                  href="https://teadesign.net"
                >
                  Посети сайта
                </a>
              </div>
            ) : (
              <div className={classes.site__info}>
                <h4>GRAPHIC DESIGNER PORTFOLIO</h4>

                <p>
                  A fascinating and enjoyable project in which I worked with and
                  helped a professional product photographer and grafic designer
                  go online and show his work.
                </p>

                <a
                  className="btn__secondary"
                  target="_blank"
                  rel="noreferrer"
                  href="https://teadesign.net"
                >
                  View it
                </a>
              </div>
            )}
          </article>
          <article className={classes.article__container}>
            <img src={require("../../../assets/rave.png")} alt="accounting" />
            {bulgarian ? (
              <div className={classes.site__info}>
                <h4>САЙТ ЗА СЧЕТОВОДНО И ПРАВНО ОБСЛУЖВАНЕ</h4>
                <p className="bg__para">
                  Мои близки приятели имат счетоводна кантора, която е доста
                  добре позната в града и аз ги насърчих да заявят и своето
                  онлайн присътвие и да намерят още клиенти.
                </p>
                <a
                  className={`btn__secondary ${classes.bg_button}`}
                  target="_blank"
                  rel="noreferrer"
                  href="https://kantorarave.com"
                >
                  Посети сайта
                </a>
              </div>
            ) : (
              <div className={classes.site__info}>
                <h4>ACCOUNTING SERVICES BUSINESS SITE</h4>
                <p>
                  A friend has an accounting office that is quite popular in our
                  town actually and I urged him to go online and find even more
                  clients!
                </p>
                <a
                  className="btn__secondary"
                  target="_blank"
                  rel="noreferrer"
                  href="https://kantorarave.com"
                >
                  View it
                </a>
              </div>
            )}
          </article>
          <article className={classes.article__container}>
            <img src={require("../../../assets/wood.png")} alt="woodworking" />
            {bulgarian ? (
              <div className={classes.site__info}>
                <h4>САЙТ ЗА ДЪРВОДОБИВ</h4>
                <p className="bg__para">
                  Приятел и баща му развиват бизнес в сферата на дърводобива и
                  също преминаха онлайн. Оказа се, че нямат конкуренти с онлайн
                  присъствие и получават няколко запитвания на ден през
                  контактната форма.
                </p>
                <a
                  className={`btn__secondary ${classes.bg_button}`}
                  target="_blank"
                  rel="noreferrer"
                  href="https://ilton.bg"
                >
                  Посети сайта
                </a>
              </div>
            ) : (
              <div className={classes.site__info}>
                <h4>WOODWORKING BUSINESS SITE</h4>
                <p>
                  A fellow and his father run logging and woodworking business
                  for years and finally went online. It turned out they have no
                  competitors with online presence and receive several inquires
                  daily through their contact form.
                </p>
                <a
                  className="btn__secondary"
                  target="_blank"
                  rel="noreferrer"
                  href="https://ilton.bg"
                >
                  View it
                </a>
              </div>
            )}
          </article>
        </section>
      </section>
    </section>
  );
};

export default WordPressProjects;
