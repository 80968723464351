import React from "react";
import About from "./About";
import ContactMe from "./ContactMe";
import Header from "./Header";
import ReactProjects from "./ReactProjects";
import WordPressProjects from "./WordPressProjects";

const Home = ({ bulgarian }) => {
  return (
    <React.Fragment>
      <Header bulgarian={bulgarian} />
      <About bulgarian={bulgarian} />
      <ReactProjects bulgarian={bulgarian} />
      <WordPressProjects bulgarian={bulgarian} />
      <ContactMe bulgarian={bulgarian} />
    </React.Fragment>
  );
};

export default Home;
