import React from "react";
import { AiFillHtml5 } from "react-icons/ai";
import {
  FaAngular,
  FaCpanel,
  FaCss3,
  FaNode,
  FaReact,
  FaWordpressSimple,
} from "react-icons/fa";
import { MdDns } from "react-icons/md";
import { SiJavascript, SiMongodb } from "react-icons/si";
import Line from "../../layout/Line";
import classes from "./About.module.css";

const About = ({ bulgarian }) => {
  return (
    <React.Fragment>
      {bulgarian ? (
        <section className={classes.about__bg} id="about">
          <section className="container">
            <div className={classes.about__heading}>
              <h2 className="bg__heading">Малко повече за мен и опита ми</h2>
              <Line />
            </div>
            <div className={classes.about__section}>
              <div>
                <img src={require("../../../assets/me.jpg")} alt="profile" />
              </div>
              <div className={classes.about__section_text}>
                <p className="bg__para">
                  Работя в IT сектора през последните 8 години, 7 от които като
                  първа линия техническа поддръжка в СайтГраунд - една от
                  най-големите независими хостинг компании в света. Помогнал съм
                  над 60 000 клиенти при проблеми, свързани с техните WordPress
                  сайтове, DNS промени и настройки, конфигурация на имейл
                  акаунти, както и отстраняване на неизправности при изпращане и
                  получаване на съобщения. Изработих също така и няколко
                  WordPress сайта, помагайки на приятели и клиенти да заявят
                  своето онлайн присъствие.
                  <br></br>
                  <br></br>
                  <section className="mobile">
                    <div className={classes.about__skills_heading}>
                      <h2 className="bg__heading">Моите технически умения</h2>
                      <Line />
                      <div className={classes.about__skills}>
                        <div>
                          <i className={classes.icon}>
                            <SiJavascript />
                          </i>
                          <h4>JavaScript</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <FaReact />
                          </i>
                          <h4>ReactJS</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <AiFillHtml5 />
                          </i>
                          <h4>HTML5</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <FaCss3 />
                          </i>
                          <h4>CSS3</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <FaCpanel />
                          </i>
                          <h4>cPanel</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <FaWordpressSimple />
                          </i>
                          <h4>WordPress</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <MdDns />
                          </i>
                          <h4>DNS</h4>
                        </div>

                        <div>
                          <i className={classes.icon}>
                            <FaNode />
                          </i>
                          <h4>NodeJS</h4>
                        </div>

                        <div>
                          <i className={classes.icon}>
                            <FaAngular />
                          </i>
                          <h4>Angular</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <SiMongodb />
                          </i>
                          <h4>MongoDB</h4>
                        </div>
                      </div>
                    </div>
                  </section>
                  През последните две години изучавам JavaScript, HTML, CSS,
                  React и други технологии, с които предприех следващата крачка
                  в своето професионално развитие. Работил съм върху и съм
                  изградил Fullstack React сайтове и съм силно мотивиран да
                  усвоя и React Native.
                  <br></br>
                  <br></br>
                  Комбинацията от техническия ми опит, комукационните умения и
                  тези, придобити по пътя на изучаването на софтуерните
                  технологии, както и желанието и интересът ми в областта, ме
                  правят идеалното попълнение за всеки тим.
                </p>
                <a
                  className={`btn__secondary ${classes.bg_button}`}
                  href="#contact"
                >
                  Пишете ми
                </a>
              </div>
            </div>
            {/* SKILLS SECTION */}
            <section className="desktop">
              <div className={classes.about__skills_heading}>
                <h2 className="bg__heading">Моите технически умения</h2>
                <Line />
                <div className={classes.about__skills}>
                  <div>
                    <i className={classes.icon}>
                      <SiJavascript />
                    </i>
                    <h4>JavaScript</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <FaReact />
                    </i>
                    <h4>ReactJS</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <AiFillHtml5 />
                    </i>
                    <h4>HTML5</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <FaCss3 />
                    </i>
                    <h4>CSS3</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <FaCpanel />
                    </i>
                    <h4>cPanel</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <FaWordpressSimple />
                    </i>
                    <h4>WordPress</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <MdDns />
                    </i>
                    <h4>DNS</h4>
                  </div>

                  <div>
                    <i className={classes.icon}>
                      <FaNode />
                    </i>
                    <h4>NodeJS</h4>
                  </div>

                  <div>
                    <i className={classes.icon}>
                      <FaAngular />
                    </i>
                    <h4>Angular</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <SiMongodb />
                    </i>
                    <h4>MongoDB</h4>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      ) : (
        <section className={classes.about__bg} id="about">
          <section className="container">
            <div className={classes.about__heading}>
              <h2>More about me and my experience</h2>
              <Line />
            </div>
            <div className={classes.about__section}>
              <div>
                <img src={require("../../../assets/me.jpg")} alt="profile" />
              </div>
              <div className={classes.about__section_text}>
                <p>
                  I've been working in the IT industry for the past 8 years, 7
                  of which as technical support at SiteGround - one of the
                  largest hosting providers. I've helped over 60 000 clients
                  with WordPress issues, DNS changes and settings, email clients
                  configuration and delivery troubleshooting. I have also built
                  several WordPress sites helping clients and friends go online.
                  <br></br>
                  <br></br>
                  <section className="mobile">
                    <div className={classes.about__skills_heading}>
                      <h2>My Tech Stack</h2>
                      <Line />
                      <div className={classes.about__skills}>
                        <div>
                          <i className={classes.icon}>
                            <SiJavascript />
                          </i>
                          <h4>JavaScript</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <FaReact />
                          </i>
                          <h4>ReactJS</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <AiFillHtml5 />
                          </i>
                          <h4>HTML5</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <FaCss3 />
                          </i>
                          <h4>CSS3</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <FaCpanel />
                          </i>
                          <h4>cPanel</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <FaWordpressSimple />
                          </i>
                          <h4>WordPress</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <MdDns />
                          </i>
                          <h4>DNS</h4>
                        </div>

                        <div>
                          <i className={classes.icon}>
                            <FaNode />
                          </i>
                          <h4>NodeJS</h4>
                        </div>

                        <div>
                          <i className={classes.icon}>
                            <FaAngular />
                          </i>
                          <h4>Angular</h4>
                        </div>
                        <div>
                          <i className={classes.icon}>
                            <SiMongodb />
                          </i>
                          <h4>MongoDB</h4>
                        </div>
                      </div>
                    </div>
                  </section>
                  For the past couple of years I've been learning JavaScript,
                  HTML, CSS, React and other technologies which help me further
                  develop as a professional. I've been working and have built
                  Fullstack React applications and currently am eager to learn
                  React Native.
                  <br></br>
                  <br></br>
                  The combination between my technical experience, communication
                  and software developer's skills, and my motivation, make me
                  the perfect fit for your team.
                </p>
                <a className={`btn__secondary ${classes.talk}`} href="#contact">
                  Let's Talk
                </a>
              </div>
            </div>
            {/* SKILLS SECTION */}
            <section className="desktop">
              <div className={classes.about__skills_heading}>
                <h2>My Tech Stack</h2>
                <Line />
                <div className={classes.about__skills}>
                  <div>
                    <i className={classes.icon}>
                      <SiJavascript />
                    </i>
                    <h4>JavaScript</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <FaReact />
                    </i>
                    <h4>ReactJS</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <AiFillHtml5 />
                    </i>
                    <h4>HTML5</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <FaCss3 />
                    </i>
                    <h4>CSS3</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <FaCpanel />
                    </i>
                    <h4>cPanel</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <FaWordpressSimple />
                    </i>
                    <h4>WordPress</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <MdDns />
                    </i>
                    <h4>DNS</h4>
                  </div>

                  <div>
                    <i className={classes.icon}>
                      <FaNode />
                    </i>
                    <h4>NodeJS</h4>
                  </div>

                  <div>
                    <i className={classes.icon}>
                      <FaAngular />
                    </i>
                    <h4>Angular</h4>
                  </div>
                  <div>
                    <i className={classes.icon}>
                      <SiMongodb />
                    </i>
                    <h4>MongoDB</h4>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      )}
    </React.Fragment>
  );
};

export default About;
