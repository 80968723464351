import React, { useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import MainNavigation from "./components/layout/MainNavigation";
import Home from "./components/screens/home/Home";
import CookingApp from "./components/screens/projects/CookingApp";
import Moviezilla from "./components/screens/projects/Moviezilla";
import MementoShop from "./components/screens/projects/MementoShop";
import Footer from "./components/layout/Footer";

function App() {
  const [bg, setBg] = useState(false);

  const bulgarianVersion = (bulgarian) => {
    if (bulgarian) {
      setBg(true);
    } else {
      setBg(false);
    }
  };

  return (
    <React.Fragment>
      <MainNavigation bulgarianVersion={bulgarianVersion} />
      <Routes>
        <Route path="/" element={<Home bulgarian={bg} />} />
        <Route
          path="/projects/cooking-app"
          element={<CookingApp bulgarian={bg} />}
        />
        <Route
          path="/projects/memento-shop"
          element={<MementoShop bulgarian={bg} />}
        />
        <Route
          path="/projects/moviezilla"
          element={<Moviezilla bulgarian={bg} />}
        />
      </Routes>
      <Footer bulgarian={bg}/>
    </React.Fragment>
  );
}

export default App;
