import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "./MainNavigation.module.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgClose } from "react-icons/cg";

const MainNavigation = ({ bulgarianVersion }) => {
  const [active, setActive] = useState("home");
  const [openMobile, setOpenMobile] = useState(true);
  const [bulgarian, setBulgarian] = useState("");

  const onMobileFlagClick = (e) => {
    if (e.target.name === "bulgarian") {
      setBulgarian(false);
    } else {
      setBulgarian(true);
    }

    setOpenMobile(true);
  };

  const onMenuClick = (e) => {
    setActive(e.target.name);
    setOpenMobile(true);
  };

  useEffect(() => {
    bulgarianVersion(bulgarian);
  }, [bulgarian]);

  return (
    <React.Fragment>
      {bulgarian ? (
        <nav>
          <button
            className={classes.lang_change_btn}
            onClick={() => setBulgarian(false)}
          >
            <img
              src={require("../../assets/britainflag.png")}
              className="bg__heading"
              alt="britain_flag"
            ></img>
          </button>
          {openMobile ? (
            <button
              onClick={() => setOpenMobile(false)}
              className={`${classes.mobile__btn}`}
            >
              <GiHamburgerMenu />
            </button>
          ) : (
            <button
              onClick={() => setOpenMobile(true)}
              className={`${classes.mobile__btn}`}
            >
              <CgClose />
            </button>
          )}

          <ul
            className={`${classes.nav} ${
              openMobile ? classes.closed : classes.open
            }`}
            id="home"
          >
            <li onClick={onMenuClick}>
              <Link
                name="home"
                className={`${classes.bg__links} ${
                  active === "home" ? classes.active : ""
                }`}
                to="/"
              >
                Начало
              </Link>
            </li>
            <li onClick={onMenuClick}>
              <a
                name="about"
                className={`${classes.bg__links} ${
                  active === "about" ? classes.active : ""
                }`}
                href="#about"
              >
                За мен
              </a>
            </li>
            <li onClick={onMenuClick}>
              <a
                name="react"
                className={`${classes.bg__links} ${
                  active === "react" ? classes.active : ""
                }`}
                href="#react"
              >
                React Apps
              </a>
            </li>
            <li onClick={onMenuClick}>
              <a
                name="WP"
                className={`${classes.bg__links} ${
                  active === "WP" ? classes.active : ""
                }`}
                href="#wordpress"
              >
                WP сайтове
              </a>
            </li>
            <li onClick={onMenuClick}>
              <a
                name="contact"
                className={`${classes.bg__links} ${
                  active === "contact" ? classes.active : ""
                }`}
                href="#contact"
              >
                Контакти
              </a>
            </li>
            <li>
              <img
                onClick={onMobileFlagClick}
                name="bulgarian"
                src={require("../../assets/britainflag.png")}
                className={classes.btn__flag}
                alt="britain_flag"
              ></img>
            </li>
          </ul>
        </nav>
      ) : (
        <nav>
          <button
            className={classes.lang_change_btn}
            onClick={() => setBulgarian(true)}
          >
            <img
              src={require("../../assets/bgflag.png")}
              className="bg__heading"
              alt="bulgarian_flag"
            ></img>
          </button>
          {openMobile ? (
            <button
              onClick={() => setOpenMobile(false)}
              className={`${classes.mobile__btn}`}
            >
              <GiHamburgerMenu />
            </button>
          ) : (
            <button
              onClick={() => setOpenMobile(true)}
              className={`${classes.mobile__btn}`}
            >
              <CgClose />
            </button>
          )}

          <ul
            className={`${classes.nav} ${
              openMobile ? classes.closed : classes.open
            }`}
            id="home"
          >
            <li onClick={onMenuClick}>
              <Link
                name="home"
                className={`${active === "home" ? classes.active : ""}`}
                to="/"
              >
                Home
              </Link>
            </li>
            <li onClick={onMenuClick}>
              <a
                name="about"
                className={`${active === "about" ? classes.active : ""}`}
                href="#about"
              >
                About Me
              </a>
            </li>
            <li onClick={onMenuClick}>
              <a
                name="react"
                className={`${active === "react" ? classes.active : ""}`}
                href="#react"
              >
                React Aps
              </a>
            </li>
            <li onClick={onMenuClick}>
              <a
                name="WP"
                className={`${active === "WP" ? classes.active : ""}`}
                href="#wordpress"
              >
                WP Sites
              </a>
            </li>
            <li onClick={onMenuClick}>
              <a
                name="contact"
                className={`${active === "contact" ? classes.active : ""}`}
                href="#contact"
              >
                Contact Me
              </a>
            </li>
            <li>
              <img
                onClick={onMobileFlagClick}
                name="britain"
                src={require("../../assets/bgflag.png")}
                className={classes.btn__flag}
                alt="bulgarian_flag"
              ></img>
            </li>
          </ul>
        </nav>
      )}
    </React.Fragment>
  );
};

export default MainNavigation;
